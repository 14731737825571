var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.departures
    ? _c(
        "div",
        { staticClass: "mvg-wrapper" },
        [
          _vm._l(_vm.departures, function (departure) {
            return [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.mvgTooltipDeparture(departure),
                      expression: "mvgTooltipDeparture(departure)",
                    },
                  ],
                  key: departure.key,
                  staticClass: "departure",
                },
                [
                  _c("span", { class: { live: departure.live } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("formatDepartureTime")(
                            departure.realtimeDepartureTime
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]
              ),
              _c("div", { key: departure.key + "line", staticClass: "line" }, [
                _c(
                  "div",
                  {
                    staticClass: "transport",
                    class: [
                      "type-" + departure.transportType,
                      "line-" + departure.label,
                    ],
                  },
                  [_vm._v(_vm._s(departure.label))]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.mvgTooltipDestination(departure),
                        expression: "mvgTooltipDestination(departure)",
                      },
                    ],
                    staticClass: "destination",
                    class: { cancelled: departure.cancelled },
                  },
                  [_vm._v(_vm._s(departure.destination))]
                ),
                _c(
                  "span",
                  {
                    staticClass: "delay",
                    class: {
                      "has-delay":
                        departure.realtimeDepartureTime >
                        departure.plannedDepartureTime,
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        Math.max(
                          0,
                          (departure.realtimeDepartureTime -
                            departure.plannedDepartureTime) /
                            60000
                        )
                      )
                    ),
                  ]
                ),
                departure.occupancy != "UNKNOWN"
                  ? _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: departure.occupancy,
                            expression: "departure.occupancy",
                          },
                        ],
                        staticClass: "occupancy",
                        class: "occupancy-" + departure.occupancy,
                      },
                      [_vm._v("■")]
                    )
                  : _vm._e(),
              ]),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }